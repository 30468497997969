import { render, staticRenderFns } from "./giftList.vue?vue&type=template&id=0f7e2efd&scoped=true&"
import script from "./giftList.vue?vue&type=script&lang=js&"
export * from "./giftList.vue?vue&type=script&lang=js&"
import style0 from "./giftList.vue?vue&type=style&index=0&id=0f7e2efd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f7e2efd",
  null
  
)

export default component.exports